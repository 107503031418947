<template>
  <CButton
    v-bind="$attrs"
    @click="copyElement"
    :variant='variant'
    :color='color'
  >
    <CIcon @click="copyElement" name="cil-copy"/>
  </CButton>
</template>
<script>
export default {
  props: {
    color: {default: 'secondary', type: String, required: false},
    variant: {default: 'ghost', type: String, required: false},
    value: {default: null, type: String, required: true}
  },
  methods: {
    copyElement() {
      navigator.clipboard.writeText(this.$props.value);

      // const value = document.getElementById('CopyElementIdTender').innerHTML;
      // const input_temp = document.createElement("input");
      // input_temp.value = value;
      // document.body.appendChild(input_temp);
      // input_temp.select();
      // document.execCommand("copy");
      // document.body.removeChild(input_temp);
    },
  }
}
</script>
