<template>
  <CRow class="w-100 justify-content-center">
    <CCard>
      <CCardHeader>
        <CRow class="align-items-center">
          <CCol col="12" sm="6" md="6" lg="6" xl="7">
            <div class="card-header-action mt-2 ml-2">
              <h2>Інформація по підключенню</h2>
            </div>
          </CCol>
          <CCol col="12" sm="6" md="6" lg="6" xl="5" class="text-right">
            <template v-if="can('bank_user')">
              <CButton
                  color="info"
                  variant="outline"
                  size="sm"
                  class="mr-1 p-1 px-2 mt-1"
                  @click="rowOffersHistory(order.id)"
              >
                <CIcon name="cil-history"/>
                Історія
              </CButton>
              <CButton
                  color="success"
                  variant="outline"
                  size="sm"
                  class="mr-1 p-1 px-2 mt-1"
                  @click="rowOffers(order.id)"
                  data-cy="tenderPreviewBtnOffers"
              >
                <CIcon name="cil-bookmark"/>
                Пропозиції: {{ order.offers_count }}
              </CButton>
              <CButton
                  v-if="order.status === 'CloseWithResult' && id_OffersWinner"
                  color="warning"
                  variant="outline"
                  size="sm"
                  class="mr-1 p-1 mt-1"
                  @click="rowOffersWinner(order.id)"
              >
                <CIcon name="cil-user"/>
                <span v-if="!is_loading">Переможець тендеру</span>
                <div v-if="is_loading" class="mx-5 px-3 align-items-center">
                  <CSpinner color="default" size="sm"/>
                </div>
              </CButton>
            </template>
            <template v-if="can('provider_user')">
              <CButton
                  v-if="offers.status === 'Winner'"
                  color="success"
                  variant="outline"
                  size="sm"
                  class="mr-1 p-1"
                  @click="rowOffersWinnerProvider()"
              >
                <CIcon name="cil-file" width="15px" heigth="15px"/>
                <span v-if="!is_loading"> Прикріпити договір</span>
                <div v-if="is_loading" class="mx-5 px-3 align-items-center">
                  <CSpinner color="default" size="sm"/>
                </div>
              </CButton>
            </template>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CCardGroup>
          <CCard class="p-3">
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 font-weight-bold">ID
                {{ can('provider_user') ? 'тендеру' : 'заявки' }}
                <btn-copy class="ml-1 p-1 px-2" :value="orderId"/>
              </p>
              <p class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">{{ order.id }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Дата старту
                {{ can('provider_user') ? 'тендеру' : 'заявки' }}</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ timeConvert(order.created_at) }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Регіон сповіщення</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ region[order.region_id] }} </p>
            </div>
            <div class="row border-bottom mb-2" v-if="can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Причина тендера</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.reason_type }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Призначення</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.channel_purpose }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Тип каналу</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.connect_type }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="order.merchant">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Мерчант</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.merchant }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Бранч А</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.branch_a }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 font-weight-bold">Адреса точки А</p>
              <p class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">{{ order.branch_a_address }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="order.connect_type !== 'Інтернет'">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Бранч Б</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.branch_b }}</p>
            </div>
            <div class="row border-bottom mb-2" v-if="order.connect_type !== 'Інтернет'">
              <p class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 font-weight-bold">Адреса точки Б</p>
              <p class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">{{ order.branch_b_address }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Вид каналу</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.consumer_type }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Швидкість, Мбіт/с</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ order.channel_speed }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Бажана дата підключення</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">{{ timeConvertTender(order.date_connection) }}</p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Вимоги до каналу:</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
                <CTextarea disabled placeholder="Не обов'язкове поле" cols="15" rows="2"
                           v-model="order.channel_required"></CTextarea>
              </p>
            </div>
            <div class="row mb-2" v-if="can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Додаткове:</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
                <CTextarea disabled placeholder="Не обов'язкове поле" cols="15" rows="2"
                           v-model="order.extra"></CTextarea>
              </p>
            </div>
          </CCard>
          <CCard class="p-3">
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Координати:</p>
              <p class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-8">{{ order.coordinates.toString() }}</p>
            </div>
            <div class="row border-bottom pb-2 mb-2">
              <p class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 font-weight-bold">Місцезнаходження на карті:</p>
              <p class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="mx-3" style="width: 350px;">
                  <GmapMap :center="center" :map-type-id="mapTypeId" :zoom="18">
                    <GmapMarker
                        v-for="(item, index) in markers"
                        :key="index"
                        :position="item.position"
                        @click="center = item.position"/>
                  </GmapMap>
                </div>
              </p>
            </div>
            <div class="row border-bottom pb-2 mb-2">
              <p class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 font-weight-bold">Прикріплені файли:</p>
              <p class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                <div class="ml-5" style="max-width: 300px;">
                  <img v-for="img in orderImages" :key="img"
                       @click="openIMG('myImg', img.toString())"
                       :id="`myImg${img.toString()}`"
                       :src="`${baseURL}/api/orders/${$route.params.id}/icons/${img}`"
                       alt="" class="preview minimized img-thumbnail"/>
                </div>
              </p>
            </div>

            <div id="myModalOpenIMG" class="openBIGIMG">
              <span class="myModalOpenIMG-close"
                    onclick="document.getElementById('myModalOpenIMG').style.display='none'">&times;</span>
              <img class="myModalOpenIMG-content" id="img01">
            </div>
            <div class="row border-bottom mb-2" v-if="can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Відповідальний ІТ РП</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8" v-if="order.chief_it_rp">
                <a
                    :href="'https://dpd.privatbank.ua/sotrs.php?ldap=' + order.chief_it_rp"
                    target="_blank">{{ chief_it_rp_name !== '' ? chief_it_rp_name : order.chief_it_rp }}</a></p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Контакт в регіоні з технічних
                питань</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8" v-if="contactTechSupport !== null">
                {{ contactTechSupport.full_name }}<br><a
                  :href="'mailto:' + contactTechSupport.email">{{ contactTechSupport.email }}</a><br><a
                  :href="'tel://'+contactTechSupport.phone">{{ convertTel(contactTechSupport.phone) }}</a></p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Контакт в ГО з питань тендеру</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8"><a href="tel:+380567369128">+38 (056) 736-91-28</a>
              </p>
            </div>
            <div class="row border-bottom mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Кінець прийому заявок</p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8"><span
                  v-if="Number(order.end_tender) !== 0">{{ timeConvertTender(order.end_tender) }}</span></p>
            </div>
            <div class="row mb-2">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">Статус <span
                  v-if="can('provider_user')">тендера</span><span v-else>заявки</span></p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
                <CBadge class="p-2 " :color="getBadgeStatus(order.status)">{{ statusTender(order.status) }}</CBadge>
              </p>
            </div>
            <div class="row" v-if="order.status === 'ResolveComments' && can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">
                                <span
                                    v-if="order.status === 'ResolveComments' && can('bank_user')">
              <ul style="list-style-type: none" class="p-0 mb-0">
                <li v-for="item in is_adminComments" :key="item.id">
                  {{ item.action === 'Зауваження від менеджера' ? item.action : '' }}
                </li>
              </ul>
              </span>
              </p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
                                <span
                                    v-if="order.status === 'ResolveComments' && can('bank_user')">
              <ul style="list-style-type: none" class="p-0 mb-0">
                <li v-for="item in is_adminComments" :key="item.id">
                  {{ item.action === 'Зауваження від менеджера' ? item.comment : '' }}
                </li>
              </ul>
              </span>
              </p>
            </div>
            <div class="row mb-2" v-if="order.status === 'ResolveComments' && can('bank_user')">
              <p class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-4 font-weight-bold">
                                <span
                                    v-if="order.status === 'ResolveComments' && can('bank_user')">
              <ul style="list-style-type: none" class="p-0 mb-0">
                <li v-for="item in is_adminComments" :key="item.id">
                  {{ item.action === 'Зауваження від чергових мереж' ? item.action : '' }}
                </li>
              </ul>
              </span>
              </p>
              <p class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-8">
                                <span
                                    v-if="order.status === 'ResolveComments' && can('bank_user')">
              <ul style="list-style-type: none" class="p-0 mb-0">
                <li v-for="item in is_adminComments" :key="item.id">
                  {{ item.action === 'Зауваження від чергових мереж' ? item.comment : '' }}
                </li>
              </ul>
              </span>
              </p>
            </div>
          </CCard>
        </CCardGroup>
      </CCardBody>
      <CCardFooter>
        <CRow class="py-2">
          <CCol col="12" sm="2" xl="3" md="3" lg="3">
            <CButton
                color="secondary"
                class="px-4 mr-1"
                @click="goBack"
            >
              Назад
            </CButton>
          </CCol>
          <CCol col="12" sm="10" xl="9" md="9" lg="9" class="row justify-content-end">
            <template v-if="can('provider_user') && thereIsTechnicalPossibility">
              <CButton color="success" class="btn btn-success" @click="isTechnicalPossibility()">Є технічна можливість
              </CButton>
            </template>
            <template v-if="can('provider_user') && !noTechnicalPossibility">
              <CButton
                  v-if="order.status === 'OpenOrder' && !showBtnOffers"
                  color="primary"
                  class="btn px-4"
                  @click="notTechnicalPossibility"
              >
                Немає технічної можливості
              </CButton>
              <CButton
                  v-if="showBtnOffers"
                  color="success"
                  class="btn px-4"
                  @click="showMyOffer"
              >
                Переглянути мої пропозиції
              </CButton>
              <template v-if="(order.status === 'OpenOrder') && !noTechnicalPossibility">
                <CButton
                    v-if="loadingOffer"
                    color="primary"
                    class="btn-block"
                    disabled

                >
                  <div class="  align-items-center">
                    <CSpinner color="default" size="sm"/>
                  </div>
                </CButton>
                <btn-provider-submit-proposal v-else :order="order"/>
              </template>
            </template>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <my-side-bar v-if="can('bank_user')">
      <template v-slot:content>
        <div class="sideBarContent">
          <btn-delegate-tender :order="order"/>
          <CButton
              v-if="btnRevokeEditing()"
              color="danger"
              class="sideBar_btn"
              @click="revokeEditing()"
          >
            Відкликати на редагування
          </CButton>
          <btn-cancel-and-close-tender type="cancel" :order="order"/>
          <CButton
              v-if="btnEditTender()"
              color="primary"
              class="sideBar_btn"
              @click="editTender"
          >
            Редагувати
          </CButton>
          <CButton
              v-if="btnChooseWinner()"
              color="primary"
              data-cy="ChooseWinner"
              class="sideBar_btn"
              @click="changeWinnerTender"
          >
            Вибрати переможця
          </CButton>
          <template v-if="btnSummarizeOrder()">
            <CButton
                v-if="loadingSummarizeTenders"
                color="primary"
                class="sideBar_btn"
                disabled
            >
              <div class="  align-items-center">
                <CSpinner color="default" size="sm"/>
              </div>
            </CButton>
            <CButton
                v-else
                color="success"
                class="sideBar_btn"
                @click="summarizeTender"
            >
              Підвести підсумки
            </CButton>
          </template>

          <btn-continue-tender type="continue" :order="order"/>
          <btn-continue-tender type="open" :order="order"/>
          <btn-cancel-and-close-tender type="close" :order="order"/>
          <btn-remark-department-tender :order="order"/>
        </div>
      </template>
    </my-side-bar>
  </CRow>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from "axios";
import statusTenderAndPropositionMixin from '../../mixins/statusTenderAndPropositionMixin';
import timeConvertMixin from '@/mixins/timeConvertMixin';
import {gmapApi} from "vue2-google-maps";
import BtnCopy from "~/Components/Button/BtnCopy.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";
import BtnContinueTender from "~/Components/ButtonAndModal/BtnContinueTender.vue";
import BtnDelegateTender from "~/Components/ButtonAndModal/btnDelegateTender.vue";
import BtnCancelAndCloseTender from "~/Components/ButtonAndModal/btnCancelAndCloseTender.vue";
import BtnRemarkDepartmentTender from "~/Components/ButtonAndModal/btnRemarkDepartmentTender.vue";
import btnProviderSubmitProposal from "~/Components/ButtonAndModal/btnProviderSubmitProposal.vue";
import MySideBar from "~/Components/SideBar/MySideBar.vue";

export default {
  name: "TenderPreview",
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  components: {
    MySideBar,
    BtnCancelAndCloseTender,
    BtnDelegateTender,
    BtnContinueTender,
    BtnRemarkDepartmentTender,
    btnProviderSubmitProposal,
    BtnCopy,
    Multiselect
  },
  data: () => {
    return {
      modalDepartmentTender: false,
      thereIsTechnicalPossibility: false,
      closed_or_cancel_tender_modal: false,
      id_OffersWinner: '',
      is_loading: false,
      noTechnicalPossibility: false,
      delegateTenderModal: false,
      loadingSummarizeTenders: false,
      is_adminComments: null,
      contactTechSupport: null,
      showBtnOffers: false,
      baseURL: '',
      orderImages: [],
      loadingOffer: false,
      order: {
        merchant: '',
        channel_purpose: "",
        connect_type: "",
        branch_a: "",
        branch_a_address: "",
        branch_b: "",
        branch_b_address: "",
        consumer_type: "",
        channel_speed: 0,
        date_connection: "",
        channel_required: "",
        coordinates: [],
        icons: [],
        extra: "",
        state_id: "",
      },

      center: {lat: 51.867287, lng: 33.480327},
      mapTypeId: "terrain",
      markers: [
        {
          position: {lat: 51.867287, lng: 33.480327}
        }
      ],
      comment: "",

      offers: {},
      chief_it_rp_name: '',
      region: {},
    }
  },
  computed: {
    google: gmapApi,
    orderId() {
      return this.order && this.order.id ? this.order?.id.toString() ?? '' : '';
    },
  },
  methods: {
    can,
    openModalDelegate() {
      this.delegateTenderModal = !this.delegateTenderModal;
    },

    btnRevokeEditing() {
      return can('revoke_tender_to_editing') && (this.order.status === 'NetworkValidation' || this.order.status === 'ManagerValidation');
    },

    revokeEditing() {
      return routerGoToName('TendersEdit', 'params-query', {
        params: this.$route.params.id,
        query: {update: true}
      });
    },

    rowOffersWinnerProvider() {
      return routerGoToName('TendersAttachDocument', 'params', {offer_id: this.offers.id.toString()});
    },

    btnEditTender() {

      if (can('edit_tender')) {
        if (this.order.status === 'NetworkValidation' || this.order.status === 'ManagerValidation' || this.order.status === 'ResolveComments') {
          return true;
        }
      }
      if (can('resolve_tender_remark')) {
        if (this.order.status === 'ResolveComments') {
          return true;
        }
      }

      return false;
    },

    btnChooseWinner() {
      if (can('choose_winner') && this.order.status === 'ConfirmManager') {
        return true;
      }
      if (can('head_choose_winner') && this.order.status === 'ConfirmITRP' || this.order.status === 'ConfirmMainOffice') {
        return true;
      }
      return false;
    },

    btnSummarizeOrder() {
      return can('summarize_tender') && this.order.status === 'SummarizeOrder';
    },


    //Other Func
    rowOffersWinner() {
      if (this.id_OffersWinner) {
        return routerGoToName('TendersAttachDocument', 'params', {offer_id: this.id_OffersWinner.toString()});
      }
      return;
    },
    // Закрити без переможця- затвердження результатів


    OffersWinner(order_id) {
      let self = this;
      self.is_loading = true;
      axios.get(`/api/orders/${order_id}/check/winner`).then(function (response) {
        self.id_OffersWinner = response.data
        self.is_loading = false;
      }).catch(function () {
        self.is_loading = false;
      });
    },

    notTechnicalPossibility() {
      let self = this;
      if (!confirm("У вас точно немає технічної можливості?")) {
        return;
      }
      axios.post(`/api/account/orders/${self.$route.params.id}/notech`).then(function () {
        self.funcShowTender();
      }).catch(function () {
      });
    },

    getBadge(status) {
      return status === true ? 'success' : status === false ? 'warning' : 'danger';
    },

    openIMG(id, imgID) {
      let modal = document.getElementById('myModalOpenIMG');
      let img = document.getElementById(`${id + imgID}`);
      let modalImg = document.getElementById("img01");
      //let captionText = document.getElementById("caption");

      modal.style.display = "block";
      modalImg.src = img.src;

      let span = document.getElementsByClassName("myModalOpenIMG-close")[0];
      span.onclick = function () {
        modal.style.display = "none";
      }
    },

    getTenderByIDProvider() {
      let self = this;
      axios.get('/api/orders/' + this.$route.params.id)
          .then(function (response) {
            self.order = response.data;
            self.order.coordinates = self.order.coordinates.split(',')
            self.markers[0].position.lat = Number(self.order.coordinates[0]);
            self.markers[0].position.lng = Number(self.order.coordinates[1]);
            self.center.lat = Number(Number(self.order.coordinates[0]));
            self.center.lng = Number(Number(self.order.coordinates[1]));
            self.getIcons();
            self.OffersWinner(self.order.id);
            self.getCheifITRPName();

          }).catch(function () {
      });
    },

    getTenderByIDUsers() {
      let self = this;
      axios.get('/api/account/orders/' + this.$route.params.id)
          .then(function (response) {
            self.order = response.data;
            self.order.coordinates = self.order.coordinates.split(',')
            self.markers[0].position.lat = Number(self.order.coordinates[0]);
            self.markers[0].position.lng = Number(self.order.coordinates[1]);
            self.center.lat = Number(Number(self.order.coordinates[0]));
            self.center.lng = Number(Number(self.order.coordinates[1]));
            self.getIcons();
            self.OffersWinner(self.order.id);
          }).catch(function () {
      });
    },

    getIcons() {
      let self = this;
      axios.get('/api/orders/' + this.$route.params.id + '/icons').then(function (response) {
        self.orderImages = response.data;
      })
    },

    editTender() {
      routerGoToName('TendersEdit', 'params', this.$route.params.id);
    },

    showMyOffer() {
      routerGoToName('TendersViewOffers', 'params', this.$route.params.id);
    },

    offerWinner() {
      let self = this;
      axios.get(`/api/account/orders/${self.$route.params.id}/offer/winner`).then(function (response) {
        self.offers = response.data;
      }).catch(function () {
      });
    },

    showBtnEditOffers() {
      let self = this;
      // axios.get('/api/account/orders/'+ self.$route.params.id +'/offers').then(function (response) {

      axios.get('/api/account/offers/' + self.$route.params.id + '/active').then(function (response) {

        if (response.data.status !== `NoTechnicalPossibility`) {
          self.showBtnOffers = true;
          self.noTechnicalPossibility = false;
          // self.offers=response.data;
          self.thereIsTechnicalPossibility = false;
          return;
        }
        self.showBtnOffers = false;
        self.noTechnicalPossibility = true;
      })
          .catch(function (error) {
            if (error.response.data.message === `OrderOffersNotFound`) {
              self.showBtnOffers = false;
              self.noTechnicalPossibility = false;
              self.thereIsTechnicalPossibility = false;
              return;

            }
            self.thereIsTechnicalPossibility = true;
            self.noTechnicalPossibility = true;
            self.showBtnOffers = false;
          });
    },

    isTechnicalPossibility() {
      let self = this;
      if (!confirm("У вас точно появилася технічна можливість?")) {
        return;
      }
      axios.delete(`/api/account/orders/${self.$route.params.id}/offer`).then(function () {
        self.funcShowTender();
      }).catch(function () {
      });
    },

    goBack() {
      routerGoToName('Tenders');
    },

    changeWinnerTender() {
      routerGoToName('TendersViewProvidersOffer', 'params', this.order.id.toString());
    },

    summarizeTender() {
      let self = this;
      self.loadingSummarizeTenders = true;
      axios.get('/api/orders/' + self.order.id + '/summarize').then(function () {
        self.loadingSummarizeTenders = false;
        self.$router.push({path: '/tenders'});
      }).catch(function () {
        self.loadingSummarizeTenders = false;
      });
    },

    rowOffers(id) {
      routerGoToName('TendersViewProvidersOffer', 'params', id.toString());
    },

    rowOffersHistory(id) {
      routerGoToName('TendersActionHistory', 'params', {order_history_id: id.toString()});
    },

    getContactTechSupport() {
      let self = this;
      axios.get(`/api/orders/${self.$route.params.id}/contacts`).then(function (response) {
        self.contactTechSupport = response.data;
      }).catch(function () {
      });
    },

    getComments() {
      let self = this;
      axios.get(`/api/orders/${self.$route.params.id}/comments`).then(function (response) {

        self.is_adminComments = response.data;

      }).catch(function () {
      });
    },

    getCheifITRPName() {
      let self = this;
      axios.get(`/api/staff/chiefs/${self.order.chief_it_rp}/name`).then((resp) => {
        self.chief_it_rp_name = resp.data;
      }).catch(() => {
        self.chief_it_rp_name = self.order.chief_it_rp;
      })
    },

    getRegion() {
      let self = this;
      axios.get(`/api/regions`).then(resp => {
        resp.data.forEach(function (item) {
          self.$set(self.region, item.id, item.region)
        })

      })
    },

    funcShowTender() {
      if (can('provider_user')) {
        this.offerWinner();
        this.showBtnEditOffers();
        this.getTenderByIDUsers();
      }
      if (can('bank_user')) {
        this.getTenderByIDProvider();
        this.getComments();
        this.getRegion();
      }
      this.getContactTechSupport();
      this.baseURL = axios.defaults.baseURL;
    }

  },
  created() {
    if (this.$store.state.login_info.ready) {
      this.funcShowTender();
    }
  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.funcShowTender();
    }
  }
}
</script>
