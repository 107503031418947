<template>
  <div>
    <CButton
        v-bind="$attrs"
        color="warning"
        class="px-4"
        @click="offerTender()"
    >
      Подати пропозицію
    </CButton>
    <CModal
        color="danger"
        title="Заповніть дані про компанію"
        :show.sync="modalErrorHideDataForCompany"
        size="lg"
        addContentClasses="srl"
    >
      <template #body-wrapper>
        <CCardHeader class="">
          <h4>Для подачі комерційної пропозиції Вам необхідно заповнити контактну інформацію в Особистому
            кабінеті.</h4>
        </CCardHeader>
        <CCardBody class="">
          Обов'язкові поля:
          <ul class="text-decoration-none">
            <li>Телефон підтримки</li>
            <li>Телефон бухгалтерії</li>
            <li>Email бухгалтерії</li>
            <li>Email технічної підтримки</li>
          </ul>
          <p>З метою спрощення комунікації та ідентифікації просимо Вас заповнити всі поля, в тому числі бренд,
            web-сторінку та контактний телефон користувачів.
            <br>Дякуємо.</p>
        </CCardBody>
      </template>
      <template #footer>
        <CButton
            color="secondary"
            @click="openModalErrorHideDataForCompany()"
        >
          Скасувати
        </CButton>
        <CButton
            color="danger"
            @click="editUserData"
        >
          Редагувати дані
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import {routerGoToName} from "@/utils/routerUtils";

export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  data() {
    return {
      modalErrorHideDataForCompany: false
    }
  },
  methods: {
    editUserData() {
      routerGoToName('ProviderInfo');
    },
    OffersLink(order_id) {
      return `/tenders/${order_id.toString()}/offer`
    },
    openModalErrorHideDataForCompany(val = false) {
      this.modalErrorHideDataForCompany = val;
    },
    offerTender() {
      let getOrganizationHasAllContacts = this.$store.getters.organizationHasAllContacts;
      if (getOrganizationHasAllContacts) {
        return this.openModalErrorHideDataForCompany(true);
      }

      let tendersLink = this.OffersLink(this.$props.order.id);
      let router = this.$router.resolve({path: tendersLink});
      window.open(router.href, '_blank');
    },
  }
}
</script>